import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AuctionRouter from 'pages/Onboarding/AuctionDashboard/AuctionRouter';
import AuthRouter from 'pages/Auth/AuthRouter';
import BidApplicationMainRouter from 'pages/Onboarding/BidApplication/MainRouter';
import BrokerLanding from 'pages/Onboarding/BrokerAdmin/BrokerLanding';
import Chat from 'pages/Onboarding/Chat/Chat';
import MainLarAppNew from 'pages/Onboarding/LarAppNew/MainLarAppNew';
import RegistrationRouterBorrower from 'pages/Registration/Borrower/MainRouter';
import RegistrationRouterBroker from 'pages/Registration/Broker/MainRouter';
import RegistrationRouterCompany from 'pages/Registration/Company/MainRouter';
import RegistrationRouterLender from 'pages/Registration/Lender/MainRouter';
import PrivateRoute from './PrivateRoute';
import MainOnboardingNavigation from './MainOnboardingNavigation';
import Registration from '../pages/Auth/Registration';

export default function MainRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>

        {/* user */}
        <Route exact path="/login" component={AuthRouter} />
        <Route exact path="/campaign" component={Registration} />
        <Route exact path="/superlogin" component={AuthRouter} />
        {/* <Route exact path="/borrowerlogin" component={AuthRouter} /> */}
        <Route exact path="/verification_code" component={AuthRouter} />
        <Route exact path="/setup_password" component={AuthRouter} />
        <Route exact path="/reset_password" component={AuthRouter} />

        <Route exact path="/portal/preview">
          <BrokerLanding previewMode />
        </Route>
        <Route exact path="/portal/:companyDomain/:brokerDomain">
          <BrokerLanding />
        </Route>

        {/* user */}

        <Route exact path="/registration/lender/:slug/:id?" component={RegistrationRouterLender} />
        <Route exact path="/company/:slug/:slug2?" component={RegistrationRouterCompany} />
        <Route exact path="/registration/broker/:slug/:id?" component={RegistrationRouterBroker} />
        <Route exact path="/registration/borrower/:slug/:id?" component={RegistrationRouterBorrower} />

        {/* LAR application new */}
        <Route exact path="/lar_app/:slug/:slug2?" component={MainLarAppNew} />
        {/* LAR application new */}

        {/* Auction dashboard */}
        <PrivateRoute exact path="/winner-selection/:id?" component={AuctionRouter} />
        {/* Auction dashboard */}

        {/* Loan Bid application */}
        <Route exact path="/bid_application/:slug/:slug2?" component={BidApplicationMainRouter} />
        {/* Loan Bid application */}

        {/* onboarding */}
        <PrivateRoute path="/onboarding/:slug/:slug2?/:slug3?/:slug4?/:slug5?">
          <MainOnboardingNavigation />
        </PrivateRoute>
        {/* onboarding */}
      </Switch>
      <Chat type="sidebar" />
      {/* <Notifications/> */}
    </Router>
  );
}
